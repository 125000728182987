import Alpine from 'alpinejs'
import header from '@/components/header.js'
import video from '@/components/video.js'
import embedVideo from '@/components/embed/video.js'

//
// Fonts
//
import '@fontsource/playfair-display/900.css'
import '@fontsource-variable/outfit'

//
// Styles
//
import '../css/style.css'

//
// Setup Alpine
//
window.Alpine = Alpine

//
// Setup Alpine components
//
Alpine.data('header', header)
Alpine.data('video', video)
Alpine.data('embedVideo', embedVideo)

//
// Initialize Alpine
//
Alpine.start()
